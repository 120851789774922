.image-picker {
    width: 100%;
    min-height: 300px;
    background-color: #f9f9f9;
    border: 0.075rem solid #323232;
    border-radius: 0.35rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.image-picker:hover {
    opacity: 0.5;
}

.image-picker input[type=file] {
    display: none;
}

.image-picker img {
    width: 100%;
    height: 100%;
}

.image-picker-error {
    margin: 1rem 0;
    max-width: 300px;
}

@media screen and (min-width: 576px) {
    .image-picker {
        max-width: 1000px;
    }
}